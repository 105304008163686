<template>
  <div class="mb-4">
    <ul class="nav nav-pills nav-justified">
      <li class="nav-item waves-effect waves-light">
        <router-link
          class="nav-link"
          :class="{ 
            active: seccion == 'calendario'
          }"
          :to="{ name: 'calendario' }"
        >
          Inicio
        </router-link>
      </li>
      <li class="nav-item waves-effect waves-light">
        <router-link
          :to="{ name: 'eventosCalendario' }"
          class="nav-link"
          :class="{
            active: seccion == 'eventos'
          }"
        >
          Eventos
        </router-link>
      </li>
      <li class="nav-item waves-effect waves-light">
        <router-link
          :to="{ name: 'ajustesCalendario' }"
          class="nav-link"
          :class="{
            active: seccion == 'ajustes'
          }"
        >
          Ajustes
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'CmpSecciones',
  props: ['seccion']
}
</script>

<style scoped>
.no-cursor{
  cursor: default;
}
</style>